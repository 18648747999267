module.exports = {
  "translation": {
    "clubinone": "俱乐部 4U",
    "home": "我的俱乐部",
    "pros": "优点",
    "persons": "人数",
    "competitions": "锦标赛",
    "competition": "锦标赛",
    "competitionUC": "锦标赛",
    "bookings": "预订",
    "statistics": "统计数据",
    "settings": "设置",
    "profile": "形象",
    "login": "登录",
    "logout": "登出",
    "organizations-list": "我的组织",
    "companies": "俱乐部",
    "company": "俱乐部",
    "searchcompanies": "搜索俱乐部",
    "searchresult": "搜索结果",
    "history": "最近",
    "register": "注册",
    "save": "拯救",
    "userData": "用户数据",
    "changePassword": "更改密码",
    "changeEmail": "更改电子邮件",
    "language": "语言",
    "firstName": "名字",
    "lastName": "姓氏",
    "birthdate": "生日",
    "password": "密码",
    "email": "电子邮件",
    "profilePicture": "简介图片",
    "takePicture": "选择图片",
    "upload": "保存图像",
    "passwordReset": "发送密码重置邮件",
    "events": "活动",
    "event": "事件",
    "eventUC": "活动",
    "loading": "加载数据...",
    "newcompanies": "成为主人",
    "erroLogin": "请登录以创建一个俱乐部!",
    "newCategory": "新类别",
    "description": "描述",
    "changeSuccessful": "更改成功",
    "changeNotSuccessful": "更改时出错",
    "deleteSuccessful": "删除成功",
    "deleteNotSuccessful": "删除时出错",
    "addSuccessful": "添加成功",
    "addNotSuccessful": "添加时出错",
    "notSuccessful": "发生了一个错误",
    "name": "命名",
    "shortName": "简称",
    "priority": "优先权",
    "delete": "删除",
    "cancel": "取消",
    "ok": "认可",
    "bookingDuration": "预订期限",
    "showAvailableSlots": "显示可用时段",
    "showBookedSlots": "显示已预订的时段",
    "withSlider": "显示滑块",
    "intervallBetweenBookings": "两次预订之间的时间",
    "maxBookingsInFuture": "最大预订量",
    "maxBookingsPerDay": "每天的最大预订量",
    "maxBookingsPerWeek": "每周最大预订量",
    "maxPersonsBookable": "最大的可预订时段数",
    "minPersonsBookable": "最少可预订的空位数",
    "newBookingType": "新的预订类型",
    "from": "期间从",
    "to": "期至",
    "cart": "頧行",
    "teetimes": "ǞǞǞ",
    "pro": "プロジェクト",
    "pros": "プロジェクト",
    "courses": "课程",
    "course": "课程",
    "ranges": "范围",
    "available": "",
    "sperrungnoshow": "已锁定",
    "showAvatar": "在预订日历中显示头像",
    "withDog": "与狗",
    "with9Hole": "计划打9洞",
    "withPlayWithMe": "乐于与我预约",
    "member": "会员",
    "memberFemale": "女性成员",
    "memberMale": "男性成员",
    "guest": "访客",
    "guestFemale": "女嘉宾",
    "guestMale": "男嘉宾",
    "displayOptions": "显示选项",
    "showName": "显示名称",
    "showHCP": "显示盘口",
    "showGender": "显示性别",
    "all": "全部",
    "min1": "最少。1个免费名额",
    "min2": "至少2个免费名额",
    "min3": "最少。3个免费名额",
    "min4": "最少。4个免费名额",
    "hcp": "残障人士",
    "syncHCP": "同步残局",
    "hcpSyncOK": "残局同步成功",
    "hcpErrorSync": "同步盘口的错误",
    "bookingTeetimeHelp": "帮忙预订茶水时间",
    "myBooking": "我的预订",
    "monday": "梦之城_梦之城娱乐_梦之城国际娱乐_梦之城国际娱乐平台",
    "tuesday": "星期二",
    "wednesday": "星期三",
    "thursday": "瑟尔",
    "friday": "礼拜五",
    "saturday": "卫星",
    "sunday": "太阳",
    "makeBooking": "预定时间",
    "makeBookingAndPay": "预订并支付茶水费",
    "makeProBooking": "预订专业课程",
    "makeAndPayProBooking": "预订和支付专业课程",
    "makeRangeBooking": "预定范围时段",
    "makeAndPayRangeBooking": "预定和支付范围的时间段",
    "maxDays": "预订的最长天数",
    "timeSwitch": "时间增加一天",
    "allowAlwaysBookingHours": "时间总是可以预订的",
    "courseConfigs": "课程配置",
    "unlimited": "无限制",
    "myCourseData": "我的数据",
    "myFutureBookings": "预订总数",
    "myBookingToday": "预订选定日期",
    "myBookingWeek": "本周预订情况",
    "bookingCourseHelp": "帮助课程配置",
    "bookingCourseHelp1": "这些配置已由高尔夫俱乐部存入。",
    "openFriends": "配置朋友",
    "editBooking": "编辑预订",
    "@me": "我",
    "player": "队员",
    "username": "身份证号码或绰号",
    "announcement": "公告",
    "participants": "参与者",
    "of": "的",
    "round": "圆的",
    "guests": "宾客",
    "webAvailable": "有网络版",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "与HCPI™无关",
    "HCPREL1": "HCPI™-相关",
    "HCPREL2": "Tiger Rabbit",
    "regPeriod": "注册时间",
    "regPeriodStart": "注册开始",
    "regPeriodEnd": "注册截止日期",
    "hcpi": "HCPITM",
    "toStr": "至",
    "timerRunning": "Teetime还在等待...",
    "timerStopped": "你的teetime现在也可以提供给其他客户。",
    "teetimeLocked": "Teetime已被封锁",
    "tournamentRegisterError1": "未登录",
    "tournamentRegisterError2": "性别不匹配",
    "tournamentRegisterError3": "障碍物不匹配",
    "tournamentRegisterError4": "注册期限不匹配",
    "tournamentRegisterError5": "参赛名单已满",
    "tournamentRegisterError6": "年龄组不匹配",
    "tournamentRegisterError7": "在#注册",
    "tournamentUnRegisterError1": "未登录",
    "tournamentUnRegisterError2": "不允许取消注册",
    "tournamentUnRegisterError3": "超过未登记日期",
    "tournamentUnRegisterError4": "未在#注册",
    "tournamentUnRegisterError5": "不允许取消注册，因为已经在线支付",
    "helpCoursesTime": "课程日期、开始和结束时间",
    "helpCoursesEnter": "注册课程",
    "helpCoursesUnregister": "从课程中取消注册",
    "helpCoursesSeries": "课程系列",
    "helpCourses": "帮助课程",
    "helpTournamentTime": "锦标赛日期、开始和结束时间",
    "helpTournamentDown": "开放的细节",
    "helpTournamentUp": "关闭详细信息",
    "helpTournamentEnter": "注册",
    "helpTournamentEnterWaitlist": "注册等待名单",
    "helpTournamentUnregister": "取消注册",
    "helpTournamentGenderFemale": "允许女性参加的锦标赛",
    "helpTournamentGenderMale": "允许男性参加的锦标赛",
    "helpTournamentML": "参赛名单",
    "helpTournamentSL": "开始列表",
    "helpTournamentEL": "结果列表",
    "helpTournamentAnnouncment": "公告",
    "helpTournament": "帮助锦标赛",
    "helpEventTime": "活动日期、开始和结束时间",
    "helpEventDown": "开放的细节",
    "helpEventUp": "关闭详细信息",
    "helpEventEnter": "登记参加活动",
    "helpEventEnterWaitlist": "注册活动候补名单",
    "helpEventUnregister": "从事件中取消注册",
    "helpEventGenderFemale": "允许女性参加的活动",
    "helpEventGenderMale": "允许男性参加的活动",
    "helpEventAnnouncment": "描述",
    "helpEvent": "帮助活动",
    "helpRoundSL": "开始列表",
    "unregister": "签出从",
    "unregisterSuccessful": "签出成功",
    "unregisterNotSuccessful": "签出时出错",
    "registerSuccessful": "成功注册",
    "registerNotSuccessful": "寄存器中的错误",
    "none": "没有选择",
    "early": "早期",
    "late": "迟到的",
    "makeTournamentRegistration": "注册",
    "makeTournamentRegistrationAndPay": "注册和支付",
    "makeTournamentUnRegistration": "取消注册",
    "comment": "评论",
    "registerTournament": "注册",
    "unregisterTournament": "取消注册",
    "addAdditionalPlayers": "额外的队友",
    "addAdditionalFlightPlayers": "额外的球员",
    "searchPlayer": "搜索玩家",
    "friends": "朋友们",
    "friendGroups": "朋友群",
    "friendsSave": "拯救朋友",
    "addAdditionalFriends": "添加朋友",
    "friendsNotConfirmed": "朋友请求",
    "friendsDeclined": "拒绝好友请求",
    "noTournaments": "没有发现锦标赛",
    "noEvents": "没有发现任何活动",
    "noTeetimeCourseFound": "没有发现teetime预订日历",
    "hole": "洞",
    "given": "鉴于",
    "noPros": "没有发现专业人员",
    "noRanges": "没有找到范围内的槽位",
    "nextFreeSlot": "今天的下一个免费插槽",
    "booked": "预定的",
    "precedingHours": "预定时段的前几小时",
    "precedingStornoMinutes": "直到可以删除预订的分钟数",
    "proHelp": "帮忙的人",
    "proConfigs": "专业配置",
    "rangeHelp": "帮助范围",
    "rangeConfigs": "范围配置",
    "noCourses": "没有找到课程",
    "coursename": "高尔夫球场",
    "placename": "地点",
    "priceinfo": "价格信息",
    "onlinecomments": "评论",
    "livescorings": "数字计分",
    "noLivescorings": "今天没有发现锦标赛",
    "noEmail": "没有可用的电子邮件地址。请与俱乐部联系，添加您的电子邮件地址。",    
    "noEmailSMSLogIn": "没有电子邮件地址。你可以通过你的手机号码设置你自己的电子邮件，或者联系俱乐部，让他们储存你的电子邮件地址.",
    "notActivated": "您的账户没有被激活。请点击发送激活链接以验证您的电子邮件。 该电子邮件地址是否不再是最新的？然后请告知高尔夫俱乐部当前的电子邮件地址并重复这一过程。",
    "sendActivationLink": "发送激活链接",
    "emailSendOK": "邮件已成功发送",
    "emailSendError": "发送邮件错误",
    "verify": "激活账户",
    "verifyEmailOK": "该账户已成功激活。",
    "verifyEmailError": "激活账户出错",
    "reset": "设置新密码",
    "resetPassword": "更改密码",
    "passwordConfirm": "确认密码",
    "password8Chars": "至少8个字符长",
    "password1UpperCase": "至少1个大写字母",
    "password1Number": "至少一个数字",
    "password1SpecialChar": "至少一个特殊字符 (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "密码匹配",
    "resetPasswordOK": "密码修改成功",
    "resetPasswordError": "更改密码错误",
    "passwordRules": "心灵。8个字符，一个大写字母，一个数字和一个特殊字符（-, +, _, !, @, #, $, %, ^, &, *, .）",
    "bookingDeleted": "预订成功删除",
    "bookingAdded": "成功添加预订",
    "dgvnumber": "10位数的ID号码（专供高尔夫协会成员使用）。",
    "dgvdescription": "现在就快速轻松地注册！。",
    "dgvdescription1": "所列三个高尔夫协会之一的成员？。",
    "dgvdescription2": "然后请选择相应的卡，并输入10位数的身份证号码，以及个人数据。",
    "searchClubs": "如果不是德国、瑞士高尔夫或奥地利俱乐部的会员，请搜索国家/俱乐部",
    "searchCountry": "搜索国家/俱乐部",
    "gender": "性别",
    "male": "男性",
    "female": "女性",
    "title": "标题",
    "activationLinkSent": "通过电子邮件发送了一个激活链接。请检查您的收件箱并激活您的账户。这封电子邮件也可能最终进入了垃圾邮件文件夹。你的登录用户是。 #",
    "cardclub": "卡片",
    "marker": "标记",
    "clear": "清楚",
    "confirm": "确认",
    "signaturePlayer": "签名球员",
    "signatureMarker": "签名标记",
    "read": "阅读",
    "gentleman": "男性",
    "ladies": "妇女",
    "missingScores": "分数丢失。不可能确认签名!",
    "hometeetimes": "Book teetime",
    "homefriends": "我的朋友",
    "homecard": "我的RFID卡",
    "homepros": "Book Pro",
    "homecourses": "预订课程",
    "homeranges": "预订范围槽",
    "homecompetitions": "锦标赛日历",
    "homelivescorings": "应用程序中的记分卡",
    "homeevents": "活动日历",
    "homeMemberdata": "我的数据",
    "homePayment": "应用内支付",
    "homeMember": "已经是会员或客人？然后在登录后直接使用所有在线服务。",
    "homeEmail": "电子邮件地址存储在俱乐部？",
    "homeNewCustomer": "现在就注册成为新客人并使用所有在线服务。",
    "myclub": "我的数据",
    "personInfo": "个人资料",
    "clubInfo": "俱乐部信息",
    "addressInfo": "地址信息",
    "bankInfo": "银行信息",
    "authorizePayments": "授权支付",
    "revokePayments": "撤销授权",
    "paypalPayment": "支付宝付款",
    "paypalAuthorized": "您已成功授权PayPal付款",
    "creditCardPayment": "信用卡支付",
    "cardholderName": "持卡人姓名",
    "creditCardNumber": "信用卡号码",
    "expiration": "终止日期",
    "accept": "添加信用卡",
    "creditCardAuthorized": "您已成功授权信用卡付款",
    "creditCardAuthorizedFailed": "授权失败",
    "noPaymentMethod": "目前还没有储存任何付款方式。只需在个人资料中保存PayPal或信用卡。",
    "paymentMethodDeleted": "支付方式已被删除",
    "paymentMethodCreated": "支付方式被添加",
    "startdate": "开始日期",
    "contractstart": "合同开始日期",
    "contractend": "合同结束日期",
    "enddate": "结束日期",
    "membership": "会员资格",
    "contract": "合同",
    "homeclubnum": "主场俱乐部号码",
    "street": "街道",
    "city": "城市",
    "telephone": "电话",
    "mobile": "手机",
    "newsletter": "通讯",
    "allowNewsletter": "订阅了",
    "notAllowNewsletter": "未订阅",
    "emailbill": "通过电子邮件发送账单",
    "allowBillEmail": "订阅了",
    "notAllowBillEmail": "未订阅",
    "accountowner": "账户所有者",
    "bank": "银行",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "借记提款",
    "debitsOk": "允许的",
    "debitsNotOk": "不允许",
    "refNumber": "任务参考号",
    "homeclub": "家庭俱乐部",
    "credit": "信用",
    "addFriendGroup": "添加朋友组",
    "addCredit": "充值信贷",
    "amount": "数量",
    "recharge": "充电",
    "automaticRechargeBeyond": "如果信用额度低于此金额，则自动充值",
    "paymenttype": "付款方式",
    "rechargeBy": "按金额增加",
    "amountToPay": "要支付的金额",
    "freeSlots": "免费老虎机",
    "addAdditionalFlightFriends": "额外的朋友",
    "card": "我的卡",
    "assignedCards": "分配的卡片",
    "valid": "有效性",
    "noCardFound": "没有找到卡片",
    "permissions": "许可权",
    "status": "状况",
    "locked": "已锁定",
    "active": "活跃",
    "cardHistory": "卡的历史",
    "noHistory": "无数据",
    "clubSelection": "俱乐部的选择",
    "bonuspoints": "奖励积分",
    "creditoncard": "卡上信贷",
    "pointsoncard": "广播卡上的点数",
    "getBalls": "要求得到球",
    "getPutting": "要求开放推杆区",
    "getCheckIn": "要求报到",
    "getDoor": "要求",
    "pressButton": "按下球机上的银色按钮",
    "ballmachineTimerRunning": "在接下来的时间里按下球机上的银色按钮。",
    "checkInTimerRunning": "在接下来的时间内按下签到上的银色按钮。",
    "doorTimerRunning": "在接下来的几分钟内按下门上的银色按钮",
    "bookingTimerRunning": "在接下来的时间内按下阅读器上的银色按钮",
    "seconds": "秒钟",
    "appButtonError": "处理该请求时出错。请在几秒钟后再试。",
    "r0000001": "创建交易时出了问题",
    "r0000002": "您已经创建了一个付款。请按下读卡器上的银色按钮以确认付款。",
    "r0000003": "该阅读器正在被其他人使用。请稍等片刻，再试一次",
    "r0000004": "这个DGV号码已经被使用了",
    "r0000005": "未找到硬件",
    "frequency0": "",
    "frequency1": "每月",
    "frequency2": "每季度",
    "frequency3": "半年一次",
    "frequency4": "每年",
    "frequency5": "每日",
    "packageAvailable": "可用的",
    "generalPackage": "球篮套餐",
    "personalPackage": "个人球篮套餐",
    "noRPRs": "今天没有发现已登记的私用轮子",
    "RPRs": "登记的私密回合",
    "RPR": "已登记的私人回合",
    "getBooking": "要求开机",
    "news": "高尔夫俱乐部新闻",
    "imprint": "版税",
    "alreadyInFlight": " 已在飞行中",
    "acceptAgb": "我接受一般条款和条件",
    "acceptDSGVO": "我接受数据保护条例",
    "agb": "一般条款和条件",
    "datalegal": "Privacy policy",
    "marshalls": "Marshall",
    "send": "发送",
    "messageToOffice": "给秘书处的信息",
    "messageSent": "信息已发送",
    "statusSet": "状态已设定",
    "holes": "孔洞",
    "tee": "发球",
    "personalScorecard": "个人记分卡",
    "getScorecard": "对记分卡",
    "length": "米数",
    "faq": "FAQs",
    "yes": "是的",
    "no": "没有",
    "checkIn": "报到",
    "checkInText": "今天的时间是否应该设置为现在？",
    "DSGVO": "隐私政策",
    "Agb": "一般条款和条件",
    "noNews": "没有消息",
    "noPlayers": "小组中没有球员",
    "functions": "下面列出的数字高尔夫体验是由该高尔夫俱乐部提供的!",
    "wantToLogout": "我想注销",
    "canLogin": "现在用指定的密码和这个10位数的号码登录。",
    "registerGuest": "来宾登记",
    "nickname": "绰号",
    "contactClub": "要改变数据，请联系俱乐部",
    "today": "今天",
    "hcpiInputHint": "输入HCPI™作为一个数字。用一个点作为小数点的分隔符。",
    "noInternet": "并非所有的结果都已传送。请检查网络连接!",
    "closestClub": "最近的俱乐部",
    "mobileScreen1": "在你的高尔夫俱乐部的数字高尔夫体验。",
    "mobileScreen2": "Rangebooking",
    "mobileScreen3": "Pros & Courses",
    "mobileScreen4": "管理卡",
    "deleteAccount": "删除账户",
    "wantToDeleteAccount": "删除账户？",
    "noWellnesses": "没有发现健康槽",
    "wellnesses": "Wellness",
    "wellnessHelp": "帮助健康",
    "wellnessConfigs": "Wellness配置",
    "makeWellnessBooking": "预订养生档期",
    "homewellnesses": "预订养生档期",
    "noTrackmans": "没有找到Trackman插槽",
    "trackmans": "trackman",
    "trackmanHelp": "帮助Trackman",
    "trackmanConfigs": "trackman配置",
    "makeTrackmanBooking": "书籍跟踪器插槽",
    "hometrackmans": "图书跟踪器插槽",
    "noIndoors": "没有找到Indoor插槽",
    "indoors": "indoor",
    "indoorHelp": "帮助Indoor",
    "indoorConfigs": "indoor配置",
    "makeIndoorBooking": "预订室内时段",
    "homeindoors": "预订室内时段",
    "countryCallingCode":"国家代码",
    "getMobileCode":"获取代码",
    "sentMobileCode":"您的验证码已经发送, 将在5分钟后失效。",
    "setNewEmail":"设置新的电子邮件",
    "setNewEmailDone":"电子邮件已经设置成功。请检查您的电子邮件收件箱以激活该账户。",
    "mobileCondeSendOK":"代码已成功发送到您的手机上。",
    "mobileCondeSendError": "发送代码时发生错误",
    "setEmailByMobileCodeOK":"电子邮件已成功设置",
    "setEmailByMobileCodeError": "设置电子邮件时发生了一个错误",
    "other":"其他",
    "alllowSearch":"我可以在搜索功能中找到（朋友名单，开始时间预订，比赛）",
    "privacyInfoscreen":"Infoscreen 显示",
    "wantToDeleteScoreCard":"你想清除分数卡吗？",
    "wantToCleanScoreCard": "你想删除所有的分数吗?",
    "createNewRound": "开始新的一轮",
    "saved":"节省了",
    "date":"日期",
    "year":"年",
    "savedScoreCard":"保存的记分卡",
    "updateAppToUSe": "请更新该应用程序以继续使用它。",
    "appUpdating": "App正在更新, 请稍等...",
    "aboutApp": "关于应用程序",
    "updateApp": "更新",
    "mobileNoZero": "不含0的手机号码",
    "ballausgabe": "获得球",
    "ballausgabeputting": "选择发球机/推杆区",
    "cardnumber": "卡号",
    "checkin": "Check-in",
    "dooropen": "开门",
    "turnon": "打开",
    "units": "单位",
    "courseInfoToday": "今天的课程信息",
    "digitalScorecard": "Tournaments Digital Scorecard",
    "digitalLivescoring": "Tournaments Livescoring",
    "saveAndGo": "保存并前往",
    "saveScoreError": "未输入所有分数",
    "courseStart": "开始",
    "courseEnd": "最后一个单位",
    "refereeText": "执行裁判员呼叫？",
    "newFlight": "该航班已经改变。请再次保存分数!",
    "教程": "教程",
    "tutorialTeetime": "图书开始时间",
    "tutorialTournaments": "管理锦标赛",
    "tutorialPros": "预订教练课和课程",
    "tutorialPersonal": "My Card / Profile / News",
    "tutorialScoring": "数字计分锦标赛",
    "tutorialUseReader": "读卡器操作",
    "payLastschrift": "通过直接借记支付",
    "payLastschriftHint": "直接借记的授权存入俱乐部。应付的金额将从存入的账户中扣除。",
    "addCreditLastschrift": "充值信贷",
    "allow4USearch": "我希望在人物搜索中被找到",
    "newsperson": "我的新闻",
    "sunsetInfoToday": "Sunset today",
    "sunsetInfo": "Sunset",
    "sunsetBooking": "开始时间可以持续到日落",
    "friendsHelp": "朋友帮助",
    "friendsAccepted": "接受/接受好友请求",
    "friendsNotYetAcceppted": "尚未接受的好友请求",
    "friendsHelpDeclined": "拒绝/拒绝好友请求",
    "friendsDelete": "删除朋友",
    "friendsFemale": "女性",
    "friendsMale": "男性",
    "friendsAdd": "添加朋友组或朋友",
    "friendsOpenDetail": "开放细节",
    "friendsCloseDetail": "关闭细节",
    "RoundFinished": "一轮完成",
    "allowSingleEntryTeamError": "不允许在团队游戏中单人进入!",
    "clearScores": "删除所有分数",
    "time": "一天中的时间",
    "useLandscape": "请在您的移动设备上使用横屏模式!",
    "general": "一般",
    "bills": "账单",
    "teetime": "Teetime booking",
    "deleteNews": "删除消息",
    "deleteThisNews": "删除这个消息?",
    "aboname": "name",
    "aboInfo": "订阅",
    "aboHistory": "订阅历史",
    "bookAndPay": "预订和支付",
    "noPaymentMethod": "为信用充值，必要时在俱乐部直接存款",
    "rounds": "轮",
    "digitalScorecardLegend1": "传输并保存分数",
    "digitalScorecardLegend2": "没有输入或改变分数",
    "digitalScorecardLegend3": "分数已经改变，但是还没有传送和保存",
    "digitalScorecardLegend4": "分数已经成功传输和保存",
    "enterEmail": "请输入存储的电子邮件，并再次请求电子邮件",
    "infoScreenTitle": "Infoscreen Display",
    "infoScreenMessage": "请选择是, 以便在室外终端 (Infoscreen)上显示。这个设置可以在Profile下再次改变!",
    "addRPR": "注册私人回合",
    "syncHCPI": "查询当前HCPI™ ...",
    "noRPR": "查询当前HCPI™是不可能的。不能注册RPR轮。",
    "searchMarker": "搜索计数器",
    "addRPRDebit": "创建RP轮,用直接借记支付",
    "addRPRRound": "创建RP轮",
    "saveRPRRound" : "RP轮已成功保存和转移",
    "saveRPRError" : "在保存RP轮时发生了一个错误",
    "notAllScoresSaved" : "保存所有分数时签名可见",
    "scorecardSaved" : "记分卡已成功保存",
    "GuestsQRCode": "数字记分卡 QRCode",
    "addLock" : "添加锁",
    "addLockSuccess" : "成功添加锁",
    "setGolfChainHeader" : "注意！激活高尔夫球链会员资格",
    "setGolfChainText" : "您真的要激活高尔夫连锁会籍吗",
    "noBlanks" : "无空格或特殊字符",
    "tendigits" : "10 位数字",
    "eightdigits" : "8 位数",
    "ninedigits" : "9 位数",
    "golfChain" : "我是 Golf Chain 会员",
    "ask9holes" : "打 18 洞还是 9 洞",
    "ask9holesText" : "请说明您打算打几个洞！",
    "9holes" : "9 洞",
    "18holes" : "18 洞",
    "pointPackages" : "点套餐",
    "points" : "积分",
    "buypoints" : "购买积分",
    "for" : "为",
    "凭证": "凭证",
    "recipient" : "收件人",
    "addRecipient" : "输入收件人",
    "howtoget" : "您希望以何种方式接收代金券",
    "post" : "Post*",
    "addAmount" : "输入金额",
    "addEmail" : "输入电子邮件地址",
    "zip" : "邮编",
    "street2" : "Street2",
    "buyVoucher" : "购买优惠券",
    "addVoucherSuccessful" : "成功购买代金券",
    "addVoucherNotSuccessful" : "购买代金券时发生错误",
    "directPayment": "付款方式",
    "homevoucher":"购买优惠券",

    "de": "德国",
    "en": "英语",
    "it": "意大利语",
    "fr": "法国",
    "cz": "捷克",
    "cn": "中国",
    "nl": "荷兰语",
    "es": "西班牙语",

    "ls0001": "您的银行信息不完整，请联系您的高尔夫俱乐部",
    "ls0002": "直接借记支付已被高尔夫俱乐部停用",
    "dfdae65b": "未发现有用户使用给定的电子邮件地址",
    "71b72952": "错误的密码",
    "71b72951": "缺少电子邮件地址。请联系俱乐部!",
    "71b72953": "代号错误",
    "71b72954": "代币过期",
    "71b72955": "无效密码",
    "df6b0128": "小组中的一名球员不允许预订",
    "df6b0129": "未登录",
    "df6b0130": "未找到用户",
    "df6b0131": "Teetime已被锁定",
    "df6b0132": "未找到Teetime",
    "df6b0133": "过去的预订",
    "df6b0134": "预订没有配置",
    "df6b0135": "预订不能被删除",
    "df6b0136": "没有空位",
    "df6b0137": "未找到课程",
    "df6b0138": "不允许登记",
    "df6b0139": "不允许取消注册",
    "df6b0140": "未找到日历",
    "df6b0141": "达到结束时间",
    "df6b0142": "预订前的时间达到",
    "df6b0143": "槽位不可用",
    "df6b0144": "时间在过去",
    "df6b0145": "达到的最大天数",
    "df6b0146": "达到删除期",
    "df6b0147": "未找到锦标赛",
    "df6b0148": "未找到专业人员",
    "df6b0149": "未找到范围",
    "df6b0150": "在线支付的Teetime",
    "df6b0151": "网上支付的范围",
    "df6b0152": "在线支付的专业人员",
    "df6b0153": "购物车不可用",
    "df6b0154": "用户锁定为 4U",
    "19e28470": "用户已经存在",
    "7e341661": "内部错误",
    "7e341662": "登录错误",
    "bfe57d09": "没有发现有此登录的用户",
    "bfe57d10": "没有用户的电子邮件",
    "b4d05e56": "上传文件时出了问题",
    "b4d05e57": "删除文件时出了问题",
    "b4d05e58": "保存数据时出了问题",
    "b4d05e59": "获取数据时出了问题",
    "b4d05e60": "该身份证号码已经注册。请使用此号码登录",
    "b4d05e61": "未找到所选国家/俱乐部",
    "b4d05e62": "系统中已经存在一个拥有所提供数据的人。用这个用户ID登录。",
    "b4d05e63": "没有找到这个人。请将所给的数据与身份证上的数据进行核对（名、姓）。"
  }
}