<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Voucher</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="vouchers && vouchers.length <= 0">{{ $t('noVouchers') }}</h3>

      <ion-card v-if="successPayment == '' && vouchers" class="ion-padding">
        <h3>{{ vouchers.header }}</h3>
        <span>{{ vouchers.headertext }}</span>
        <ion-card-header style="font-weight: bold;">
          <ion-radio-group v-model="selectedVoucher" :value="selectedVoucher">
            <ion-item v-for="(item, index) in vouchers.vouchers" :key="index" :value="item">
              <ion-label text-wrap>{{ item.name }} {{ item.price }}</ion-label>
              <ion-radio :value="index"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-card-header>
        <ion-list>
          <ion-item>
            <ion-label>{{ $t('recipient') }}: </ion-label>
            <ion-input v-model="recipient" fill="outline" :placeholder="$t('addRecipient')"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label slot="start">{{ $t('amount') }}: </ion-label>
            <ion-label v-if="vouchers && vouchers.vouchers[selectedVoucher].pricefloat > 0" slot="end" style="font-weight: bold;">{{ vouchers.vouchers[selectedVoucher].price }} </ion-label>
            <ion-input v-model="amount" v-if="vouchers && vouchers.vouchers[selectedVoucher].pricefloat <= 0" fill="outline" :placeholder="$t('addAmount')"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">{{ $t('howtoget') }}</ion-label>
            <ion-radio-group v-model="selectedEmail" value="email">
              <ion-grid>
                <ion-row justify-content-center align-items-center style='height: 100%'>
                  <ion-col ><ion-label style="margin-top: 5px;">{{$t('email')}}</ion-label></ion-col>
                  <ion-col><ion-radio value="email"></ion-radio></ion-col>
                  <ion-col><ion-label style="margin-top: 5px;">{{$t('post')}}</ion-label></ion-col>
                  <ion-col><ion-radio value="post"></ion-radio></ion-col>
                </ion-row>
              </ion-grid>
            </ion-radio-group>
          </ion-item>
          <ion-list v-if="selectedEmail == 'email'">
            <ion-item>
              <ion-input v-model="email" fill="outline" :placeholder="$t('Email')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="firstname" fill="outline" :placeholder="$t('firstName')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="lastname" fill="outline" :placeholder="$t('lastName')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="mobile" fill="outline" :placeholder="$t('mobile')"></ion-input>
            </ion-item>
          </ion-list>

          <ion-list v-if="selectedEmail != 'email'">
            <ion-item>
              <ion-input v-model="firstname" fill="outline" :placeholder="$t('firstName')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="lastname" fill="outline" :placeholder="$t('lastName')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="street" fill="outline" :placeholder="$t('street')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="street2" fill="outline" :placeholder="$t('street2')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="city" fill="outline" :placeholder="$t('city')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="zip" fill="outline" :placeholder="$t('zip')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="country" fill="outline" :placeholder="$t('country')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="email" fill="outline" :placeholder="$t('email')"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input v-model="mobile" fill="outline" :placeholder="$t('mobile')"></ion-input>
            </ion-item>
          </ion-list>

          <ion-button @click="doPayment()" :disabled="checkFields()">
            <ion-label>{{ $t('buyVoucher') }}</ion-label>
          </ion-button>

          <ion-item v-show="clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canPaypal && paymentClicked">
            <ion-label position="stacked">{{ $t('paymenttype')}}</ion-label>
            <div style="margin-top: 5px;" id="paypalButton"></div>
          </ion-item>

        </ion-list>
        <br><br>
        <p style="font-size: small">{{ vouchers.hint1 }}</p>
        <p style="font-size: small">{{ vouchers.hint2 }}</p>
      </ion-card>

      <ion-card v-if="vouchers && successPayment != ''" class="ion-padding">
        <h3>{{ $t('Der Kauf des Gutschein  "' + vouchers.vouchers[selectedVoucher].name + '" war erfolgreich') }}</h3>
        <h5>Ihre Paypal {{ successPayment }}</h5>
        <h5>Viel Freude mit dem Gutschein!</h5>
        <ion-button @click="resetData()">
          <ion-label>OK</ion-label>
        </ion-button>
      </ion-card>

    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonCardContent } from '@ionic/vue';
import { defineComponent, watch, ref, computed } from 'vue';
import { personOutline, calendarClearSharp, newspaperOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getVouchersQueryFile from '../graphql/getVouchers.query.gql'
import addVoucherMutationFile from '../graphql/addVoucher.mutation.gql'
import addVoucherLoggedInMutationFile from '../graphql/addVoucherLoggedIn.mutation.gql'
import createPaymentVoucherMutationFile from '../graphql/createPaymentVoucher.mutation.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
import store from '../main'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'Vouchers',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCardContent, IonButton,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
    const selectedVoucher = ref(0)
    const selectedEmail = ref('email')
    const clubInfo = ref(getClubInfo())
    const paymentClicked = ref(false)
    const recipient = ref('')
    const amount = ref('')
    const email = ref('')
    const firstname = ref('')
    const lastname = ref('')
    const mobile = ref('')
    const street = ref('')
    const street2 = ref('')
    const zip = ref('')
    const city = ref('')
    const country = ref('')
    const paymentToken = ref('')
    const successPayment = ref('')
    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 

    const { mutate: addVoucherLoggedInMutation } = useMutation(addVoucherLoggedInMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: addVoucherMutation } = useMutation(addVoucherMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutationVoucher } = useMutation(createPaymentVoucherMutationFile, { fetchPolicy: 'no-cache' });

    const { result: getVouchersResult, loading: getVouchersLoading } = useQuery(getVouchersQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const vouchers = useResult(getVouchersResult, null, data => data.getVouchers)

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getVouchersLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getVouchersLoading, (getVouchersLoading, prevLoading) => {
      if (!getVouchersLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getVouchersLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getVouchersLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      vouchers, personOutline, calendarClearSharp, dayjs, newspaperOutline, selectedVoucher, selectedEmail, clubInfo, paymentClicked, recipient, amount, email, firstname, lastname, mobile, street, street2, zip, city, street, country, paymentToken, successPayment, isLoggedIn,
      createPaymentMutationVoucher, addVoucherMutation, addVoucherLoggedInMutation
    }
  },
  methods: {
    resetData() {
      this.email = ''
      this.firstname = ''
      this.lastname = ''
      this.mobile = ''
      this.amount = ''
      this.selectedEmail = 'email'
      this.street = ''
      this.street2 = ''
      this.zip = ''
      this.city = ''
      this.country = ''
      this.recipient = ''
      this.paymentClicked = false
      this.successPayment = ''
    },
    checkFields() {
      let recipientOK = false
      let amountOK = false
      let mainDataOK = false
      let postDataOK = false

      if (this.recipient.trim() != '')
        recipientOK = true
      
      if (this.vouchers.vouchers[this.selectedVoucher].pricefloat > 0 || (this.vouchers.vouchers[this.selectedVoucher].pricefloat <= 0 && this.amount != ''))
        amountOK = true

      if (this.email != '' && this.email.includes('@') && this.email.includes('.') && this.firstname != '' && this.lastname != '' && this.mobile != '')
        mainDataOK = true
      
      if (this.selectedEmail == 'email')
        postDataOK = true
      else if (this.street != '' && this.zip != '' && this.city != '' && this.country != '')
        postDataOK = true

      return !(recipientOK && amountOK && mainDataOK && postDataOK && !this.paymentClicked)
    },
    doPayment() {
      this.paymentClicked = true
      loadScript({ "client-id": this.clubInfo.clubInfo.paypalClientID, currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          let amount = this.amount
          if (this.selectedEmail == "post")
            amount = parseFloat(amount) + 3.9
          if (this.vouchers.vouchers[this.selectedVoucher].pricefloat > 0 && this.selectedEmail == "post")
            this.vouchers.vouchers[this.selectedVoucher].pricefloat = parseFloat(this.vouchers.vouchers[this.selectedVoucher].pricefloat) + 3.9
          const p = await this.createPaymentMutationVoucher({ description: 'Gutschein', amount: this.vouchers.vouchers[this.selectedVoucher].pricefloat <= 0 ? parseFloat(amount) : parseFloat(this.vouchers.vouchers[this.selectedVoucher].pricefloat), paymentType: 'payPal'})
          if (!p.data.createPaymentVoucher.success) {
            return ''
          }
          this.paymentToken = p.data.createPaymentVoucher.token
          return p.data.createPaymentVoucher.token
         },
         onError: async (error) => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
            loadingController.create().then(res => {
              res.present()
            }).catch(err => { })
            if (this.isLoggedIn) {
              this.addVoucherLoggedInMutation({ 
                amount: (this.vouchers.vouchers[this.selectedVoucher].pricefloat <= 0 ? parseFloat(this.amount) : parseFloat(this.vouchers.vouchers[this.selectedVoucher].pricefloat)),
                type: this.paymenttype,
                email: this.email,
                orderId: data.orderID,
                name: this.firstname + ' ' + this.lastname,
                sendto: this.selectedEmail,
                mobile: this.mobile,
                street: this.street,
                street2: this.street2,
                zip: this.zip,
                city: this.city,
                country: this.country,
                token: this.paymentToken,
                recipient: this.recipient,
                id: this.vouchers.vouchers[this.selectedVoucher].id
                })
              .then(res => {
                //console.log(res)
                  this.paymentClicked = false
                  this.successPayment = 'OrderID: ' + res.data.addVoucherLoggedIn.addInfo
                  toastController
                    .create({
                      message: this.$t('addVoucherSuccessful'),
                      duration: 3000,
                      color: 'success'
                    }).then(res => res.present())
                    loadingController.dismiss()
              })
              .catch(err => {
                console.log(err)
                  loadingController.dismiss()
                  toastController
                    .create({
                      message: this.$t('addVoucherNotSuccessful') + ': ' + err,
                      duration: 3000,
                      color: 'danger'
                    }).then(res => res.present())
              }) 
            }
            else {
              this.addVoucherMutation({ 
                amount: (this.vouchers.vouchers[this.selectedVoucher].pricefloat <= 0 ? parseFloat(this.amount) : parseFloat(this.vouchers.vouchers[this.selectedVoucher].pricefloat)),
                type: this.paymenttype,
                email: this.email,
                orderId: data.orderID,
                name: this.firstname + ' ' + this.lastname,
                sendto: this.selectedEmail,
                mobile: this.mobile,
                street: this.street,
                street2: this.street2,
                zip: this.zip,
                city: this.city,
                country: this.country,
                token: this.paymentToken,
                recipient: this.recipient,
                id: this.vouchers.vouchers[this.selectedVoucher].id
                })
              .then(res => {
                //console.log(res)
                  this.paymentClicked = false
                  this.successPayment = 'OrderID: ' + res.data.addVoucher.addInfo
                  toastController
                    .create({
                      message: this.$t('addVoucherSuccessful'),
                      duration: 3000,
                      color: 'success'
                    }).then(res => res.present())
                    loadingController.dismiss()
              })
              .catch(err => {
                console.log(err)
                  loadingController.dismiss()
                  toastController
                    .create({
                      message: this.$t('addVoucherNotSuccessful') + ': ' + err,
                      duration: 3000,
                      color: 'danger'
                    }).then(res => res.present())
              }) 
            }
         },
         onCancel: async () => {
          await this.createPaymentMutationVoucher({ amount: this.vouchers.vouchers[this.selectedVoucher].pricefloat <= 0 ? parseFloat(amount) : parseFloat(this.vouchers.vouchers[this.selectedVoucher].pricefloat), token: this.paymentToken, paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});

    this.paymenttype = 'PayPalAccount'
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>