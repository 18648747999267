<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('aboutApp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'AboutAppDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,IonCardContent
              },
  setup() {
    return { 
      closeOutline
    }
  },
  mounted() {
  },
  data() {
    return{
      currentPackageInfo: '',
      remotePackageInfo: '',
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>